import {
  IScopeTwoChart,
  IScopeTwoDataChart,
  IScopeTwoDataEmission,
  IScopeTwoDataEmissionTarget,
  IScopeTwoEmissionRenewablePercentages,
  IScopeTwoEmissionsTarget,
  IScopeTwoDataPerformanceReivew,
  IScopeTwoEmissionDataChart,
  IScopeTwoRequestHistory,
} from "@/models/ScopeTwoEmissions";
import { ErrorResponse } from "@/services/axios/error";
import { ScopeTwoEmissionAPI } from "@/services/scopeTwoEmissions";
import store from "@/store";
import {
  covertDataChartLine,
  covertDataChartMonthValue,
  covertDataChartScope1And3,
  covertDataScope2Target,
} from "@/utils/helpers/convertDataChart";
import { getYear } from "@/utils/helpers/convertDateTime";
import { sumEnergyOneYear } from "@/utils/helpers/buildDataFormat";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "ScopeTwoEmissions";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class ScopeTwoEmissionModule extends VuexModule {
  private error = {} as ErrorResponse;
  private errorScopeTwoEnergyLoad = {} as ErrorResponse;
  private errorScopeTwoGeneratedEnergy = {} as ErrorResponse;
  private errorScopeTwoRenewableEnergy = {} as ErrorResponse;
  private errorScopeTwoTarget = {} as ErrorResponse;
  private errorScopeTwoPerformanceReview = {} as ErrorResponse;
  private isLoadingScopeTwoEmission = false as boolean;
  private isLoadingPerformanceReview = false as boolean;
  private isLoadingScopeTwoEmissionChart = false as boolean;

  private dataScopeTwoEnergyLoad = [] as IScopeTwoDataChart[][];
  private dataScopeTwoGeneratedEnergy = [] as IScopeTwoDataChart[][];
  private dataScopeTwoRenewableEnergy = [] as IScopeTwoDataChart[][];
  private dataScopeTwoEmissionTarget = [] as IScopeTwoDataEmissionTarget[][];
  private dataScopeLineMarker = [] as IScopeTwoDataChart[][];
  private dataScopeTwoEmissionChart = [] as IScopeTwoEmissionDataChart[][];
  private dataScopeTwoEmission = {} as IScopeTwoDataEmission;
  private dataScopeTwoEmissionBase = {} as IScopeTwoEmissionDataChart;
  private dataScopeTwoTargetPercent = 0 as number | string;
  private currentYear = new Date().getFullYear() as string | number | Date;
  private facility = "" as string;
  private dataMaxPercentChart = 100 as number;
  private dataScopeTwoPerformanceReview = {} as IScopeTwoDataPerformanceReivew;

  private sumScopeTwoEnergyLoad = 0 as number;
  private renewableOptionsTotal = [] as any[];

  private dataAmountChart = {} as any;
  private isLoadingAmountRECsChart = false;

  get renewableOptionsTotalGetter() {
    return this.renewableOptionsTotal;
  }

  get dataAmountChartGetter() {
    return this.dataAmountChart;
  }

  get isLoadingAmountRECsChartGetter() {
    return this.isLoadingAmountRECsChart;
  }
  @Mutation
  loadingScopeTwoEmission(isLoading = false as boolean) {
    this.isLoadingScopeTwoEmission = isLoading;
  }

  get dataScopeTwoEnergyLoadGetter() {
    return this.dataScopeTwoEnergyLoad;
  }
  get loadingScopeTwoEnergyLoadGetter() {
    return this.isLoadingScopeTwoEmission;
  }

  get sumScopeTwoEnergyLoadGetter() {
    return this.sumScopeTwoEnergyLoad;
  }

  @Mutation
  setS2LoadEnergyError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
  }

  @Mutation
  setS2LoadEnergySuccess(dataS2LoadEnergy: IScopeTwoDataChart[][]) {
    this.dataScopeTwoEnergyLoad = dataS2LoadEnergy;
    this.isLoadingScopeTwoEmission = false;
  }

  @Action({ rawError: true })
  async fetchS2LoadEnergy(payload: IScopeTwoChart) {
    this.loadingScopeTwoEmission(true);
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEnergyLoad(payload);
    if (error) {
      this.setS2LoadEnergyError(error);
    } else {
      const dataConvert = covertDataChartMonthValue(data);
      return { data, dataConvert };
    }
  }

  get dataScopeTwoGeneratedEnergyGetter() {
    return this.dataScopeTwoGeneratedEnergy;
  }

  @Mutation
  setS2GeneratedEnergyError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
  }

  @Mutation
  setS2GeneratedEnergySuccess(dataS2GeneratedEnergy: IScopeTwoDataChart[][]) {
    this.dataScopeTwoGeneratedEnergy = dataS2GeneratedEnergy;
  }

  @Action({ rawError: true })
  async fetchS2GeneratedEnergy(payload: IScopeTwoChart) {
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoGeneratedEnergy(payload);
    if (error) {
      this.setS2LoadEnergyError(error);
    } else {
      const dataConvert = covertDataChartMonthValue(data);
      return dataConvert;
    }
  }

  get dataScopeTwoRenewableEnergyGetter() {
    return this.dataScopeTwoRenewableEnergy;
  }

  @Mutation
  setS2RenewableEnergyError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
  }

  @Mutation
  setS2RenewableEnergySuccess(dataS2RenewableEnergy: IScopeTwoDataChart[][]) {
    this.dataScopeTwoRenewableEnergy = dataS2RenewableEnergy;
  }

  @Action({ rawError: true })
  async fetchS2RenewableEnergy(payload: IScopeTwoChart) {
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoRenewableEnergy(payload);
    if (error) {
      this.setS2LoadEnergyError(error);
      return {};
    } else {
      const dataConvert = covertDataChartMonthValue(data);
      return { data, dataConvert };
    }
  }

  get dataScopeTwoEmissionTargetGetter() {
    return this.dataScopeTwoEmissionTarget;
  }
  get dataScopeTwoEmissionGetter() {
    return this.dataScopeTwoEmission;
  }
  @Mutation
  setS2EmissionError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
  }

  @Mutation
  setS2EmissionTargetSuccess(dataS2Emission: IScopeTwoDataEmissionTarget[][]) {
    this.dataScopeTwoEmissionTarget = dataS2Emission;
  }

  @Mutation
  setS2EmissionSuccess(dataS2Emission: IScopeTwoDataEmission) {
    this.dataScopeTwoEmission = dataS2Emission;
  }
  @Action({ rawError: true })
  async fetchS2Emission(payload: IScopeTwoEmissionsTarget) {
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsTarget(payload);
    if (error) {
      this.setS2LoadEnergyError(error);
    } else {
      this.setS2EmissionSuccess(data);
      const dataConvert = covertDataScope2Target(data);
      return { dataConvert, data };
    }
  }
  get dataScopeTwoLineMarkerGetter() {
    return this.dataScopeLineMarker;
  }
  get dataScopeTwoTargetPercentGetter() {
    return this.dataScopeTwoTargetPercent;
  }

  get dataMaxPercentChartGetter() {
    return this.dataMaxPercentChart;
  }

  get dataCurrentYearScopeTwoEmissionGetter() {
    return this.currentYear;
  }

  @Mutation
  currentYearScopeTwoEmission(value: string | number | Date) {
    if (typeof value === "number") {
      this.currentYear = value;
    } else {
      this.currentYear = getYear(value);
    }
  }
  get dataCurrentFacilityScopeTwoEmissionGetter() {
    return this.facility;
  }

  @Mutation
  currentFacilityScopeTwoEmission(value: string) {
    this.facility = value;
  }
  @Action({ rawError: true })
  fetchAllDataChartEmission(payload: any) {
    this.loadingScopeTwoEmission(true);
    return Promise.all([
      this.fetchS2RenewablePercentages(payload),
      this.fetchS2Emission(payload),
      this.fetchS2PerformanceReview(payload),
    ]).then((values: any) => {
      this.scopeTwoEmissionRenewablePercentagesSuccess(values[0]);
      this.setS2EmissionTargetSuccess(values[1] && values[1].dataConvert);
      this.setS2PerformanceReviewSuccess(values[2]);
      this.generateAmountRECsChart({
        Total: values[0] && values[0].RenewableEnergies,
        Consumed: values[0] && values[0].EnergyLoads,
        Target: values[1] && values[1].data.Target,
      });
      this.loadingScopeTwoEmission(false);
    });
  }

  @Action({ rawError: true })
  fetchAllChartEmission(payload: any) {
    this.loadingScopeTwoEmission(true);
    Promise.all([
      this.fetchS2RenewablePercentages(payload),
      this.fetchS2PerformanceReview(payload),
    ]).then((values: any) => {
      this.scopeTwoEmissionRenewablePercentagesSuccess(values[0]);
      this.setS2PerformanceReviewSuccess(values[1]);
      this.loadingScopeTwoEmission(false);
    });
  }

  @Mutation
  scopeTwoEmissionRenewablePercentagesSuccess(
    data: IScopeTwoEmissionRenewablePercentages
  ) {
    if (!data) return;
    const { dataSum, maxPercent, dataConvert } = covertDataChartLine(
      data.RenewablePercentages,
      data.EnergyLoads,
      data.RenewableEnergies
    );
    this.dataScopeTwoTargetPercent = dataSum;
    this.dataMaxPercentChart = maxPercent * 100 > 100 ? maxPercent * 100 : 100;
    this.dataScopeTwoRenewableEnergy = covertDataChartMonthValue(
      data.RenewableEnergies
    );
    this.dataScopeTwoEnergyLoad = covertDataChartMonthValue(data.EnergyLoads);
    this.dataScopeLineMarker = dataConvert;
    this.isLoadingScopeTwoEmission = false;
  }
  @Action({ rawError: true })
  async fetchS2RenewablePercentages(payload: IScopeTwoChart) {
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsRenewablePercentages(
        payload
      );
    if (error) {
      this.setS2LoadEnergyError(error);
      return null;
    } else {
      //this.scopeTwoEmissionRenewablePercentagesSuccess(data);
      return data;
    }
  }

  get dataScopeTwoPerformanceReviewConsumedGetter() {
    return this.dataScopeTwoPerformanceReview?.PerformanceOverviewConsumed;
  }
  get dataScopeTwoPerformanceReviewRenewableGetter() {
    return this.dataScopeTwoPerformanceReview?.PerformanceOverviewRenewable;
  }
  get isLoadingPerformanceReviewGetter() {
    return this.isLoadingPerformanceReview;
  }
  @Mutation
  setLoadingPerformanceReview(isLoading: boolean) {
    this.isLoadingPerformanceReview = isLoading;
  }
  @Mutation
  setS2PerformanceReviewSuccess(
    dataS2PerformanceReview: IScopeTwoDataPerformanceReivew
  ) {
    this.dataScopeTwoPerformanceReview = dataS2PerformanceReview;
    this.isLoadingPerformanceReview = false;
  }

  @Mutation
  setS2PerformanceReviewError(error: ErrorResponse) {
    this.errorScopeTwoPerformanceReview = error;
    this.isLoadingPerformanceReview = false;
  }

  @Action({ rawError: true })
  async fetchS2PerformanceReview(payload: IScopeTwoEmissionsTarget) {
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    this.setLoadingPerformanceReview(true);
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsPerformanceReview(
        payload
      );
    if (error) {
      this.setS2PerformanceReviewError(error);
    } else {
      return data;
    }
  }

  get dataScopeTwoEmissionChartGetter() {
    return this.dataScopeTwoEmissionChart;
  }
  get dataScopeTwoEmissionBaseGetter() {
    return this.dataScopeTwoEmissionBase;
  }
  get isLoadingScopeTwoEmissionChartGetter() {
    return this.isLoadingScopeTwoEmissionChart;
  }

  @Mutation
  setS2EmissionChart(isLoading: boolean) {
    this.isLoadingScopeTwoEmissionChart = isLoading;
  }

  @Mutation
  setS2EmissionChartError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
    this.isLoadingScopeTwoEmissionChart = false;
  }

  @Mutation
  setS2EmissionChartSuccess(
    dataS2EmissionChart: IScopeTwoEmissionDataChart[][]
  ) {
    this.dataScopeTwoEmissionChart = dataS2EmissionChart;
    this.isLoadingScopeTwoEmissionChart = false;
  }

  @Mutation
  setS2EmissionBaseSuccess(dataS2EmissionBase: IScopeTwoEmissionDataChart) {
    this.dataScopeTwoEmissionBase = dataS2EmissionBase;
    this.isLoadingScopeTwoEmissionChart = false;
  }
  @Action({ rawError: true })
  async fetchS2EmissionChart(payload: IScopeTwoChart) {
    this.setS2EmissionChart(true);
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsChart(payload);
    if (error) {
      this.setS2EmissionChartError(error);
    } else {
      const dataConvert = covertDataChartScope1And3(data);
      this.setS2EmissionChartSuccess(dataConvert);
      return dataConvert;
    }
  }

  @Action({ rawError: true })
  async fetchS2EmissionYoy(payload: IScopeTwoChart) {
    this.setS2EmissionChart(true);
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsChart(payload);
    if (error) {
      this.setS2EmissionChartError(error);
    } else {
      this.setS2EmissionBaseSuccess(data);
    }
  }

  @Mutation
  setLoadingAmountRECsChart(isLoading = false) {
    this.isLoadingAmountRECsChart = isLoading;
  }
  @Action({ rawError: true })
  getAllDataAmountRECsChart(payload: any) {
    this.setLoadingAmountRECsChart(true);
    return Promise.all([
      this.fetchS2RenewablePercentages(payload),
      this.fetchS2Emission(payload), //
    ]).then((values: any) => {
      this.generateAmountRECsChart({
        Total: values[0] && values[0].RenewableEnergies,
        Consumed: values[0] && values[0].EnergyLoads,
        Target: values[1] && values[1].data.Target,
      });
      this.setLoadingAmountRECsChart(false);
    });
  }
  @Mutation
  generateAmountRECsChart(rest: any) {
    this.dataAmountChart = {
      Total: sumEnergyOneYear(rest.Total) / 1000, //convert to MWh
      Target: rest.Target,
      Consumed: sumEnergyOneYear(rest.Consumed) / 1000, //convert to MWh
    };
  }

  @Action({ rawError: true })
  async getScopeTwoHistoryAction(payload: IScopeTwoRequestHistory) {
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } = await serviceScopeTwoEmissionAPI.getScopeTwoHistory(
      payload
    );
    if (error) {
      return null;
    } else {
      return data;
    }
  }
}

export default getModule(ScopeTwoEmissionModule);
