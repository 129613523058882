import {
  IScopeTwoChart,
  IScopeTwoEmissionsTarget,
  IScopeTwoRequestHistory,
} from "@/models/ScopeTwoEmissions";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const SCOPE_TWO_EMISSIONS = "ScopeTwoEmissions";
const env = JSON.parse(localStorage.getItem("env") as string);
export class ScopeTwoEmissionAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getScopeTwoEnergyLoad = async (
    params: IScopeTwoChart
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}/energyLoads`, { params })
      .catch(catchAxiosError);
    return response;
  };

  public getScopeTwoGeneratedEnergy = async (
    params: IScopeTwoChart
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}/generatedEnergies`, {
        params,
      })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoRenewableEnergy = async (
    params: IScopeTwoChart
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}/renewableEnergies`, {
        params,
      })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoEmissionsTarget = async (
    params: IScopeTwoEmissionsTarget
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}/target`, { params })
      .catch(catchAxiosError);

    return response;
  };
  public getScopeTwoEmissionsRenewablePercentages = async (
    params: IScopeTwoEmissionsTarget
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}/renewablePercentages`, {
        params,
      })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoEmissionsPerformanceReview = async (
    params: IScopeTwoEmissionsTarget
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}/performanceReview`)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoEmissionsChart = async (
    params: IScopeTwoChart
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/${params.Year}`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoHistory = async (
    params: IScopeTwoRequestHistory
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE_TWO_EMISSIONS}/history`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
